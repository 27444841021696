@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
html,
body {
  background-color: #000;
  padding: 0;
  margin: 0;
}

* {
  font-family: 'IBM Plex Sans', 'IBM Plex Mono', sans-serif;
}

:root {
  --primary: #e2e600;
  --red: #ff3b20;
  --dark-0: #000000;
  --dark-1: #181819;
  --dark-2: #2c2c2d;
  --dark-3: #404142;
  --dark-4: #606163;
  --dark-5: #fff;

  --c1: 8px;
  --c2: 16px;
  --c3: 24px;
  --c4: 32px;
  --c5: 40px;
  --c6: 48px;
  --c7: 56px;
  --c8: 64px;
  --c9: 72px;
  --c10: 80px;
  --c11: 88px;
  --c12: 96px;
  --c13: 104px;
  --c14: 112px;
  --c15: 120px;
  --c16: 128px;
  --c17: 136px;
  --c18: 144px;
  --c19: 152px;
  --c20: 160px;
  --c21: 168px;
  --c22: 176px;
  --c23: 184px;
  --c24: 192px;
  --c25: 200px;
  --c26: 208px;
  --c27: 216px;
  --c28: 224px;
  --c29: 232px;
  --c30: 240px;
  --c31: 248px;
  --c32: 256px;
  --c33: 264px;
  --c34: 272px;
  --c35: 280px;
  --c36: 288px;
  --c37: 296px;
  --c38: 304px;
  --c39: 312px;
  --c40: 320px;
  --c41: 328px;
  --c42: 336px;
  --c43: 344px;
  --c44: 352px;
  --c45: 360px;
  --c46: 368px;
  --c47: 376px;
  --c48: 384px;
  --c49: 392px;
  --c50: 400px;
  --c51: 408px;
  --c52: 416px;
  --c53: 424px;
  --c54: 432px;
  --c55: 440px;
  --c56: 448px;
  --c57: 456px;
  --c58: 464px;
  --c59: 472px;
  --c60: 480px;
  --c61: 488px;
  --c62: 496px;
  --c63: 504px;
  --c64: 512px;
  --c65: 520px;
  --c66: 528px;
  --c67: 536px;
  --c68: 544px;
  --c69: 552px;
  --c70: 560px;
  --c71: 568px;
  --c72: 576px;
  --c73: 584px;
  --c74: 592px;
  --c75: 600px;
  --c76: 608px;
  --c77: 616px;
  --c78: 624px;
  --c79: 632px;
  --c80: 640px;
  --c81: 648px;
  --c82: 656px;
  --c83: 664px;
  --c84: 672px;
  --c85: 680px;
  --c86: 688px;
  --c87: 696px;
  --c88: 704px;
  --c89: 712px;
  --c90: 720px;
  --c91: 728px;
  --c92: 736px;
  --c93: 744px;
  --c94: 752px;
  --c95: 760px;
  --c96: 768px;
  --c97: 776px;
  --c98: 784px;
  --c99: 792px;
  --c100: 800px;

  --extraSmall: 8px;
  --small: 16px;
  --medium: 24px;
  --large: 40px;
  --extraLarge: 64px;

  --text-body-small: 12pt;
  --text-body-medium: 14pt;
  --text-body-large: 16pt;

  --text-header-small: 18px;
  --text-header-medium: 22px;
  --text-header-large: 33px;

  --text-display: 54px;
}

.App {
  color: #fff;
}
br {
  height: 0;
  width: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.link {
  color: #e2e600;
  color: var(--primary);
}
button {
  color: #fff;
  transition: 0.1s ease;
  box-sizing: border-box;
  border: 1px solid #2c2c2d;
  border: 1px solid var(--dark-2);
  background-color: #000000;
  background-color: var(--dark-0);
  border-radius: 8px;
  border-radius: var(--extraSmall);
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  font-size: 14px;
  padding: 24px;
  padding: var(--medium);
  cursor: pointer;
}
button:hover {
  background-color: #181819;
  background-color: var(--dark-1);
}

.button-like {
  font-size: 18px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  color: #e2e600;
  color: var(--primary);
}

.latitude-web-wrapper {
  color: #fff;
  width: calc(1416px - 24px);
  width: calc(1416px - var(--c3));
  padding-top: 72px;
  padding-top: var(--c9);
  padding-bottom: 72px;
  padding-bottom: var(--c9);
}
@media screen and (max-width: 1626px) {
  .latitude-web-wrapper {
    width: calc(100% - 208px);
    width: calc(100% - var(--c26));
    padding-top: 72px;
    padding-top: var(--c9);
    padding-bottom: 72px;
    padding-bottom: var(--c9);
  }
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
  .latitude-web-wrapper {
    width: calc(100% - 48px);
    width: calc(100% - var(--c6));
  }
}

/* media query reference (maybe move all queries to one place in the future) */

@media screen and (max-width: 2140px) {
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
}
/* 
*********************************
*             FORMS             *
*********************************
 */

input[type='text'],
input[type='email'],
select,
textarea {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #2c2c2d;
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 4px #000000;
  box-shadow: inset 0px 0px 4px var(--dark-0);
  border-radius: 8px;
  border-radius: var(--extraSmall);
  padding: 16px;
  padding: var(--small);
  font-size: 12pt;
  font-size: var(--text-body-small);
  resize: vertical;
}

label {
  margin: 16px;
  margin: var(--small);
  margin-bottom: 8px;
  margin-bottom: var(--extraSmall);
}

input.btn {
  color: #181819;
  color: var(--dark-1);
  background-color: #e2e600;
  background-color: var(--primary);
  border: 1px solid #2c2c2d;
  border: 1px solid var(--dark-2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border-radius: var(--extraSmall);
  padding: 16px;
  padding: var(--small);
  padding-left: 24px;
  padding-left: var(--medium);
  padding-right: 24px;
  padding-right: var(--medium);
  font-size: 12pt;
  font-size: var(--text-body-small);
  display: inline-block;
  text-transform: uppercase;
  font-family: 'IBM Plex Mono';
  cursor: pointer;
  flex: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* 
*********************************
*           ARTICLES            *
*********************************
 */

.article {
  box-sizing: border-box;
  width: 50vw;
  line-height: 1.8em;
  margin: 0 auto;
  margin-top: 5vw;
  margin-bottom: 5vw;
  border-radius: 8px;
  border-radius: var(--extraSmall);
  background-color: #181819;
  background-color: var(--dark-1);
  border: 1px solid #2c2c2d;
  border: 1px solid var(--dark-2);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  padding: 64px;
  padding: var(--extraLarge);
}
.article h1 {
  font-size: 64px;
  line-height: 1em;
}
.article a {
  text-decoration: underline;
  color: #e2e600;
  color: var(--primary);
}
.article p {
  letter-spacing: 0.03em;
  margin-top: 24px;
  margin-top: var(--medium);
  margin-bottom: 24px;
  margin-bottom: var(--medium);
}
.article img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #404142;
  border: 1px solid var(--dark-3);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.article figure {
  text-align: center;
}
.article code {
  box-sizing: border-box;
  background-color: #000000;
  background-color: var(--dark-0);
  padding: 16px;
  padding: var(--small);
  overflow-x: scroll;
  display: inline-block;
  max-width: 100%;
  font-family: 'IBM Plex Mono';
  border: 1px solid #404142;
  border: 1px solid var(--dark-3);
  border-radius: 4px;
  font-size: 14px;
}
.article figure img {
  width: 75%;
  border-radius: 4px;
  border: 1px solid #404142;
  border: 1px solid var(--dark-3);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
.article figcaption {
  width: 75%;
  margin: 0 auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}
p.article-date {
  font-family: 'IBM Plex Mono';
  margin: 0;
  opacity: 0.5;
  letter-spacing: 0em;
}

li {
  margin-bottom: 24px;
  margin-bottom: var(--medium);
  list-style-type: circle;
}

h3 {
  margin-top: 0;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
}

form {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .article h1 {
    font-size: 40px;
  }
  .article {
    width: 100%;
    padding: 16px;
    padding: var(--small);
    margin-top: 10vw;
  }
}

.vertical-table {
  position: relative;
  float: left;
  width: calc(100% - 40px);
  width: calc(100% - var(--large));
}

.table-row:last-child {
  border-bottom: 0;
}

.table-row {
  cursor: pointer;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 24px;
  padding-top: var(--medium);
  padding-bottom: 24px;
  padding-bottom: var(--medium);
  padding-right: 24px;
  padding-right: var(--medium);
  border-bottom: 1px solid #2c2c2d;
  border-bottom: 1px solid var(--dark-2);
  width: 100%;
}

.table-anchor {
  flex: 1 1;
  display: flex;
  margin-left: 40px;
  margin-left: var(--large);
  width: calc(100% - 40px);
  width: calc(100% - var(--large));
  border-bottom: 1px solid #2c2c2d;
  border-bottom: 1px solid var(--dark-2);
}
.table-anchor:last-child {
  border-bottom: 0;
}

@-webkit-keyframes hero_blink__1VdaH {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hero_blink__1VdaH {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hero_hero-container__3-mwQ {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  height: calc(100vh - var(--c40));
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABg2lDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kc8rRFEUxz8zfowYjfIjC4tJwwqNURMbZaShJk1jlF+bmWd+qPnxeu9JslW2ihIbvxb8BWyVtVJESnbKmtig57wZNZPMuZ17Pvd77zndey7Yoxklq1d7IZsztEgw4J6ZnXM7nqmljRYctMYUXR0Jh0NUtI87bFa86bVqVT73rzUsJnQFbHXCw4qqGcLjwqEVQ7V4W7hFSccWhU+FezS5oPCtpceL/GJxqshfFmvRyCjYm4TdqTKOl7GS1rLC8nI82cyy8nsf6yXORG56SmKneAc6EYIEcDPBGKP46WdIZj+9+OiTFRXyvYX8SfKSq8issorGEinSGPSIuizVExKToidkZFi1+v+3r3pywFes7gxAzZNpvnWBYwu+N03z89A0v4+g6hEucqX8/AEMvou+WdI8++Bah7PLkhbfgfMNaH9QY1qsIFWJ25NJeD2Bxllovob6+WLPfvc5vofomnzVFezuQbecdy38AB0JZ8QssXrcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAH0lEQVQ4jWP4//+/1P///6UYyARM5GocBaNgFAw2AAAO5QYx7r6JmAAAAABJRU5ErkJggg==);
  display: flex;
  align-items: center;
}

.hero_hero-container__3-mwQ h1 {
  font-size: 64px;
  font-weight: 600;
}
.hero_hero-container__3-mwQ p {
  font-size: 24px;
  font-weight: 400;
}

h1 .hero_cursor-blink__sfrzZ {
  display: inline-block;
  color: var(--primary);
  -webkit-animation: hero_blink__1VdaH 1s infinite;
          animation: hero_blink__1VdaH 1s infinite;
}

.hero_hero-content__2aH0w {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABg2lDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kc8rRFEUxz8zfowYjfIjC4tJwwqNURMbZaShJk1jlF+bmWd+qPnxeu9JslW2ihIbvxb8BWyVtVJESnbKmtig57wZNZPMuZ17Pvd77zndey7Yoxklq1d7IZsztEgw4J6ZnXM7nqmljRYctMYUXR0Jh0NUtI87bFa86bVqVT73rzUsJnQFbHXCw4qqGcLjwqEVQ7V4W7hFSccWhU+FezS5oPCtpceL/GJxqshfFmvRyCjYm4TdqTKOl7GS1rLC8nI82cyy8nsf6yXORG56SmKneAc6EYIEcDPBGKP46WdIZj+9+OiTFRXyvYX8SfKSq8issorGEinSGPSIuizVExKToidkZFi1+v+3r3pywFes7gxAzZNpvnWBYwu+N03z89A0v4+g6hEucqX8/AEMvou+WdI8++Bah7PLkhbfgfMNaH9QY1qsIFWJ25NJeD2Bxllovob6+WLPfvc5vofomnzVFezuQbecdy38AB0JZ8QssXrcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAH0lEQVQ4jWP4//+/1P///6UYyARM5GocBaNgFAw2AAAO5QYx7r6JmAAAAABJRU5ErkJggg==);
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .hero_hero-container__3-mwQ h1 {
    font-size: 42px;
  }
  .hero_hero-content__2aH0w {
    padding-top: var(--c2);
  }
  .hero_hero-container__3-mwQ p {
    font-size: 18px;
    width: 80%;
    opacity: 0.8;
  }
  .hero_hero-container__3-mwQ {
    height: 85vh;
  }
  video {
    object-position: 60%;
  }
}

.section_section-container__3I7Sy {
  width: 100%;
  background-color: var(--dark-1);
  border-top: 1px solid var(--dark-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.section_section-container__3I7Sy.section_dark__2lRA6 {
  background-color: var(--dark-0);
}
.section_section-container__3I7Sy.section_light__23ORp {
  background-color: var(--dark-3);
}

.section_section-container__3I7Sy.section_grid__3gJ2I {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABg2lDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kc8rRFEUxz8zfowYjfIjC4tJwwqNURMbZaShJk1jlF+bmWd+qPnxeu9JslW2ihIbvxb8BWyVtVJESnbKmtig57wZNZPMuZ17Pvd77zndey7Yoxklq1d7IZsztEgw4J6ZnXM7nqmljRYctMYUXR0Jh0NUtI87bFa86bVqVT73rzUsJnQFbHXCw4qqGcLjwqEVQ7V4W7hFSccWhU+FezS5oPCtpceL/GJxqshfFmvRyCjYm4TdqTKOl7GS1rLC8nI82cyy8nsf6yXORG56SmKneAc6EYIEcDPBGKP46WdIZj+9+OiTFRXyvYX8SfKSq8issorGEinSGPSIuizVExKToidkZFi1+v+3r3pywFes7gxAzZNpvnWBYwu+N03z89A0v4+g6hEucqX8/AEMvou+WdI8++Bah7PLkhbfgfMNaH9QY1qsIFWJ25NJeD2Bxllovob6+WLPfvc5vofomnzVFezuQbecdy38AB0JZ8QssXrcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAH0lEQVQ4jWP4//+/1P///6UYyARM5GocBaNgFAw2AAAO5QYx7r6JmAAAAABJRU5ErkJggg==);
  border-bottom: 0;
}
.section_section-label__2frZZ {
  font-size: 24px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  margin-bottom: var(--large);
}

@media screen and (max-width: 700px) {
  .section_section-label__2frZZ {
    font-size: 18px;
  }
}

br {
  height: 0;
  width: 0;
}

p {
  line-height: 1.5em;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-transform: rotateZ(360);
          transform: rotateZ(360);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* media query reference (maybe move all queries to one place in the future) */

@media screen and (max-width: 1919px) {
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
}

/* 
*********************************
*           PANELS             *
*********************************
 */

.main_arcade-ui-panel__3bU65 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid var(--dark-2);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease;
  width: 100%;
}

.main_arcade-ui-panel__3bU65.main_padded__QXHr_ {
  padding: 16px;
}

.main_arcade-ui-panel__3bU65.main_unpadded__3cGxO {
  padding: 0px;
}

.main_arcade-ui-panel__3bU65.main_higher__1jyDK {
  background-color: var(--dark-2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.main_arcade-ui-panel__3bU65.main_default__39eRe {
  background-color: #1c1c1c;
}

.main_arcade-ui-panel__3bU65.main_blank__1fSoo {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid transparent;
}

.main_arcade-ui-panel__3bU65.main_centered__14QHd {
  left: calc(50% - 8px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.main_arcade-ui-panel__3bU65.main_hover__1sLZo {
  transition: width 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: width 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  transition: width 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
}

.main_arcade-ui-panel__3bU65.main_hover__1sLZo:hover {
  -webkit-transform: scale(1.07) translateY(-10px);
          transform: scale(1.07) translateY(-10px);
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.main_arcade-ui-panel__3bU65.main_no-shadow__2qNhu {
  box-shadow: none;
}
.main_arcade-ui-panel__3bU65.main_no-border__1U5jW {
  border: 1px solid transparent;
}

/* 
*********************************
*         PAGES & GRIDS         *
*********************************
 */

.main_arcade-ui-page-overflow__28rGO {
  position: absolute;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}

.main_arcade-ui-page-container__1a9Qb {
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  width: 1872px;
  min-height: 100vh;
  max-height: 100vh;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.main_arcade-ui-sidebar-left__1GP3b {
  grid-column: 1 / span 1;
}

.main_arcade-ui-sidebar-left-page__ZiHRf {
  grid-column: 2 / span 3;
}

.main_arcade-ui-sidebar-right__2lHY9 {
  grid-column: 4 / span 1;
}

.main_arcade-ui-sidebar-right-page__2UviA {
  grid-column: 1 / span 3;
}

/* SPAN SIZES */

.main_arcade-ui-span-1__3Fivq {
  grid-column: auto / span 1;
}

.main_arcade-ui-span-2__bKd1N {
  grid-column: auto / span 2;
}

.main_arcade-ui-span-3__tMQBA {
  grid-column: auto / span 3;
}

.main_arcade-ui-span-4__2bvNi {
  grid-column: auto / span 4;
}

/* GRID SIZES */

.main_arcade-ui-grid-8__30W0x {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.main_arcade-ui-grid-6__CheQW {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.main_arcade-ui-grid-4__3b_6E {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.main_arcade-ui-grid-3__939JE {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.main_arcade-ui-grid-2__3H0m4 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--small);
}

@media screen and (max-width: 1919px) {
  .main_arcade-ui-page-container__1a9Qb {
    width: calc(100% - 32px);
  }

  .main_arcade-ui-page-sidebar__20Z72 {
    width: 25%;
  }

  .main_arcade-ui-page-sidebar-page__sUksT {
    padding-left: 25%;
  }
}

@media screen and (max-width: 1416px) {
  /* SPAN SIZES */

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 3;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 3;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 3;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 3;
  }

  /* GRID SIZES */

  .main_arcade-ui-grid-3__939JE {
    grid-template-columns: 1fr 1fr;
  }

  .main_arcade-ui-grid-4__3b_6E {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .main_arcade-ui-grid-6__CheQW {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .main_arcade-ui-grid-8__30W0x {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--small);
  }

  .main_arcade-ui-page-sidebar__20Z72 {
    width: 33.333%;
  }

  .main_arcade-ui-page-sidebar-page__sUksT {
    padding-left: 33.333%;
  }
}

@media screen and (max-width: 976px) {
  /* SPAN SIZES */

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 3;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 3;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 3;
  }

  /* GRID SIZES */

  .main_arcade-ui-grid-3__939JE {
    grid-template-columns: 1fr;
  }

  .main_arcade-ui-grid-4__3b_6E {
    grid-template-columns: 1fr 1fr;
  }
  .main_arcade-ui-grid-6__CheQW {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .main_arcade-ui-grid-8__30W0x {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--small);
  }

  .main_arcade-ui-page-sidebar__20Z72 {
    width: calc(100% - var(--medium));
    position: absolute;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }

  .main_arcade-ui-page-sidebar-page__sUksT {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  /* SPAN SIZES */

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-3__939JE .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-4__3b_6E .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-6__CheQW .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-2__bKd1N {
    grid-column: auto / span 1;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-3__tMQBA {
    grid-column: auto / span 2;
  }

  .main_arcade-ui-grid-8__30W0x .main_arcade-ui-span-4__2bvNi {
    grid-column: auto / span 2;
  }

  /* GRID SIZES */

  .main_arcade-ui-grid-4__3b_6E {
    grid-template-columns: 1fr;
  }

  .main_arcade-ui-grid-6__CheQW {
    grid-template-columns: 1fr 1fr;
  }

  .main_arcade-ui-grid-8__30W0x {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* 
*********************************
*             TEXT              *
*********************************
 */

.main_arcade-ui-text__USa6F {
  margin-top: -0.3em;
  margin-bottom: -0.4em;
  display: inline-block;
  font-size: var(--text-body-medium);
}

.main_arcade-ui-text-mono__2C8Td {
  text-transform: uppercase;
  font-family: 'IBM Plex Mono' !important;
  display: inline-block;
  letter-spacing: 0.05em;
}

.main_arcade-ui-text__USa6F.main_body__3DSOf {
  line-height: 1.9em;
}

.main_arcade-ui-text__USa6F.main_header__778fz.main_large__2Uffi,
.main_arcade-ui-text-mono__2C8Td.main_header__778fz.main_large__2Uffi {
  font-weight: bold;
  font-size: var(--text-header-large);
}

.main_arcade-ui-text__USa6F.main_header__778fz.main_small__2WvM2,
.main_arcade-ui-text-mono__2C8Td.main_header__778fz.main_small__2WvM2 {
  font-weight: bold;
  font-size: var(--text-header-small);
}

.main_arcade-ui-text__USa6F.main_header__778fz.main_medium__dRN9p,
.main_arcade-ui-text-mono__2C8Td.main_header__778fz.main_medium__dRN9p {
  font-weight: bold;
  font-size: var(--text-header-medium);
}

.main_arcade-ui-text__USa6F.main_body__3DSOf.main_large__2Uffi,
.main_arcade-ui-text-mono__2C8Td.main_body__3DSOf.main_large__2Uffi {
  font-size: var(--text-body-large);
}

.main_arcade-ui-text__USa6F.main_body__3DSOf.main_small__2WvM2,
.main_arcade-ui-text-mono__2C8Td.main_body__3DSOf.main_small__2WvM2 {
  font-size: var(--text-body-small);
}

.main_arcade-ui-text__USa6F.main_body__3DSOf.main_medium__dRN9p,
.main_arcade-ui-text-mono__2C8Td.main_body__3DSOf.main_medium__dRN9p {
  font-size: var(--text-body-medium);
}

.main_arcade-ui-text__USa6F.main_display__38rZX,
.main_arcade-ui-text-mono__2C8Td.main_display__38rZX {
  font-weight: bold;
  font-size: var(--text-display);
}

.main_arcade-ui-text-overflow__2GDUi {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

/* 
*********************************
*           BUTTONS             *
*********************************
 */

.main_arcade-ui-button__3cfpD {
  display: flex;
  grid-gap: var(--small);
  gap: var(--small);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.2s ease, -webkit-filter 0.2s ease;
  transition: filter 0.2s ease, background-color 0.2s ease;
  transition: filter 0.2s ease, background-color 0.2s ease, -webkit-filter 0.2s ease;
  color: #fff;
  text-transform: capitalize;
}

.main_arcade-ui-button__3cfpD .main_arcade-ui-text__USa6F {
  margin-top: -0.5em;
}

.main_arcade-ui-button__3cfpD.main_medium__dRN9p {
  border: 0;
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
}

.main_arcade-ui-button__3cfpD.main_large__2Uffi {
  border: 0;
  height: var(--c7);
  padding-left: 40px;
  padding-right: 40px;
}

.main_arcade-ui-button__3cfpD.main_small__2WvM2 {
  font-weight: normal;
  border: 0;
  padding: 4.5px;
  padding-left: 16px;
  padding-right: 16px;
}

.main_arcade-ui-button__3cfpD.main_primary__2BdYj,
.main_arcade-ui-button__3cfpD.main_submit__24o4n,
.main_arcade-ui-button__3cfpD.main_floating-action__2SWfa,
.main_arcade-ui-button__3cfpD.main_floating-action-desktop__1hEn2 {
  font-weight: bold;
  background-color: var(--primary);
  color: var(--dark-0) !important;
}

.main_arcade-ui-button__3cfpD.main_secondary__2Jonm {
  background-color: var(--dark-2);
}

.main_arcade-ui-button__3cfpD.main_round__1C6jw {
  border-radius: 8px;
}

.main_arcade-ui-button__3cfpD.main_rounder__3b7eV {
  border-radius: 16px;
}

.main_arcade-ui-button__3cfpD.main_roundest__38S21 {
  border-radius: 999px;
}

.main_arcade-ui-button__3cfpD:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.main_arcade-ui-button__3cfpD:active {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.main_arcade-ui-button__3cfpD.main_floating-action__2SWfa {
  display: none;
  z-index: 999;
}

.main_arcade-ui-button__3cfpD.main_blank__1fSoo {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 16px;
  color: var(--dark-5);
}

.main_arcade-ui-button__3cfpD.main_icon__3UvOk {
  background-color: var(--dark-2);
  max-height: 42px;
  max-width: 42px;
  padding: 13px;
  border-radius: 999px;
  color: var(--dark-5);
}

.main_arcade-ui-button__3cfpD.main_blank__1fSoo:hover {
  background-color: rgba(255, 255, 255, 0.165);
}

.main_arcade-ui-button__3cfpD.main_link__ooejl {
  background-color: transparent;
  font-size: var(--text-body-small) !important;
  color: var(--primary);
}
.main_arcade-ui-button__3cfpD.main_link__ooejl .main_arcade-ui-text__USa6F {
  font-size: var(--text-body-small) !important;
}

@media screen and (max-width: 976px) {
  .main_arcade-ui-button__3cfpD.main_floating-action-desktop__1hEn2 {
    display: none;
  }

  .main_arcade-ui-button__3cfpD.main_floating-action__2SWfa {
    min-width: 25vw;
    display: flex;
    position: fixed;
    bottom: 24px;
    right: 24px;
    border-radius: 999px;
  }
}

@media screen and (max-width: 700px) {
  .main_arcade-ui-button__3cfpD.main_floating-action-desktop__1hEn2 {
    display: none;
  }

  .main_arcade-ui-button__3cfpD.main_floating-action__2SWfa {
    display: flex;
    position: fixed;
    width: calc(100vw - 28px);
    bottom: 14px;
    right: 14px;
    border-radius: 999px;
  }
}

/* 
*********************************
*          TABLE-ROW            *
*********************************
 */
.main_vertical-table__LiYyx {
  position: relative;
  float: left;
  margin-left: var(--large);
  width: calc(100% - var(--large));
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.main_table-row__2oXKa:last-child {
  border-bottom: 0;
}

.main_table-row__2oXKa {
  float: left;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: var(--medium);
  padding-bottom: var(--medium);
  padding-right: var(--medium);
  border-bottom: 1px solid var(--dark-1);
}

.main_table-row__2oXKa div {
  flex: 1 1;
}

/* 
*********************************
*            MODALS             *
*********************************
 */

@-webkit-keyframes main_modal__1ChQ_ {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes main_modal__1ChQ_ {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes main_modal-mobile__2ggD7 {
  from {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes main_modal-mobile__2ggD7 {
  from {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.main_modal-transition-container__1woci {
  position: absolute;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  z-index: 999;
  overflow: hidden;
}

.main_modal-bg___moWp {
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow: hidden;
  overflow: hidden;
}

.main_modal-panel__2NjjD {
  -webkit-animation: main_modal__1ChQ_ 0.3s ease-out;
          animation: main_modal__1ChQ_ 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #414141;
  background-color: var(--dark-1);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  width: calc(25% - 4px);
  text-align: center;
}

.main_modal-panel__2NjjD.main_alt__3bUBV {
  border: 0;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
  padding: var(--large);
}

.main_modal-action-strip__dpnS4 {
  box-sizing: border-box;
  border-top: 1px solid var(--dark-2);
  display: flex;
  flex-direction: row;
  padding: var(--small);
  margin-top: var(--small);
  width: 100%;
  grid-gap: var(--small);
  gap: var(--small);
}

.main_modal-action-strip__dpnS4 button {
  flex: 1 1;
}
.main_modal-action-strip__dpnS4.main_vertical__3RBnd {
  flex-direction: column;
}
.main_modal-action-strip__dpnS4.main_vertical__3RBnd button {
  flex: none;
}

.main_modal-panel-content__3NYtn {
  display: flex;
  flex-direction: column;
  padding: var(--medium);
  overflow-y: auto;
}

@media screen and (max-width: 1416px) {
  .main_modal-panel__2NjjD {
    width: 33%;
  }
}

@media screen and (max-width: 976px) {
  .main_modal-panel__2NjjD {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .main_modal-panel__2NjjD {
    position: relative;
    width: 100%;
    height: calc(100% - var(--c10));
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: calc(-1 * var(--extraSmall));
    -webkit-animation: main_modal-mobile__2ggD7 0.7s cubic-bezier(0.5, 0.5, 0, 1);
            animation: main_modal-mobile__2ggD7 0.7s cubic-bezier(0.5, 0.5, 0, 1);
  }
  .main_modal-panel__2NjjD.main_alt__3bUBV {
    height: auto;
    position: unset;
    -webkit-animation: main_modal__1ChQ_ 0.3s ease-out;
            animation: main_modal__1ChQ_ 0.3s ease-out;
  }
  .main_modal-panel-content__3NYtn {
    flex: 1 1;
  }
  .main_modal-action-strip__dpnS4 {
    flex-direction: column;
  }
  .main_modal-action-strip__dpnS4 button {
    min-height: var(--c7);
  }
  .main_modal-bg___moWp {
    align-items: flex-end;
  }
}

/* 
*********************************
*          TEXT-INPUT           *
*********************************
 */

input[type='text'].main_text-input__3xcj_,
input[type='password'].main_text-input__3xcj_ {
  color: #fff;
  background-color: var(--dark-0);
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 0px var(--dark-1);
  border-radius: var(--extraSmall);
  padding: var(--small);
  font-size: var(--text-body-small);
}
textarea.main_text-input-area__1fsZV {
  color: #fff;
  background-color: var(--dark-0);
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 0px var(--dark-1);
  border-radius: var(--extraSmall);
  padding: var(--small);
  font-size: var(--text-body-small);
  resize: vertical;
  max-height: 30vh;
  min-height: var(--c4);
}

/* 
*********************************
*            PILLS              *
*********************************
 */

.main_pill__1m4yr {
  box-sizing: border-box;
  background-color: transparent;
  color: #fff;
  padding: var(--extraSmall);
  padding-left: var(--small);
  padding-right: var(--small);
  border: 2px solid transparent;
  border-radius: 999px;
  cursor: pointer;
  transition: background-color 0.2s ease, -webkit-filter 0.2s ease, -webkit-transform 0.2s ease;
  transition: filter 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
  transition: filter 0.2s ease, transform 0.2s ease, background-color 0.2s ease, -webkit-filter 0.2s ease, -webkit-transform 0.2s ease;
  position: relative;
  text-transform: uppercase;
  font-family: 'IBM Plex Mono';
}
.main_pill__1m4yr.main_notification__YL0bF::after {
  color: rgb(231, 132, 40);
  font-size: 32px;
  content: '•';
  position: absolute;
  top: -20px;
  right: -5px;
}
.main_pill__1m4yr:hover {
  border: 2px solid var(--dark-2);
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}
.main_pill__1m4yr:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  background-color: var(--dark-0);
  border-color: var(--dark-0);
}
.main_pill__1m4yr.main_active__xwkST {
  background-color: var(--dark-2);
  color: var(--dark-5);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

/* 
*********************************
*             NAV               *
*********************************
 */

.main_nav-spacer__1X4az {
  height: 93px;
}

/* 
*********************************
*           CHECKBOX            *
*********************************
 */
.main_checkbox__3AMbO {
  -webkit-appearance: none;
          appearance: none;
  height: var(--c3);
  width: var(--c3);
  box-sizing: border-box;
  border: 2px solid var(--dark-4);
  border-radius: 4px;
  box-shadow: 0px 1px 2px var(--dark-0);
  position: relative;
}
.main_checkbox__3AMbO:checked {
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--primary);
  border-color: var(--primary);
}
.main_checkbox__3AMbO:checked::after {
  top: 60%;
  left: 68%;
  -webkit-transform: translate(-50%, -50%) rotate(130deg);
          transform: translate(-50%, -50%) rotate(130deg);
  color: var(--dark-1);
  font-family: 'IBM Plex Sans' !important;
  font-size: var(--text-header-large);
  font-weight: bold;
  content: '¬';
  position: absolute;
}

/* 
*********************************
*           ERROR             *
*********************************
 */

.main_error-container___cNqm {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}
.main_error__1C8il {
  -webkit-animation: main_modal__1ChQ_ 0.5s ease;
          animation: main_modal__1ChQ_ 0.5s ease;
  display: flex;
  grid-gap: var(--c2);
  gap: var(--c2);
  align-items: center;
  font-family: 'IBM Plex Mono' !important;
  font-size: var(--text-body-small);
  background-color: rgba(255, 10, 10, 0.1);
  border: 2px solid rgba(255, 10, 10, 0.15);
  padding: var(--c1);
  padding-left: var(--c2);
  padding-right: var(--c2);
  border-radius: var(--extraSmall);
  color: var(--red);
}
.main_error-text__3WQWF {
  font-family: 'IBM Plex Mono' !important;
}

/* 
*********************************
*           SUCCESS             *
*********************************
 */

.main_success-container__1Uxdg {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}
.main_success__19u60 {
  -webkit-animation: main_modal__1ChQ_ 0.5s ease;
          animation: main_modal__1ChQ_ 0.5s ease;
  display: flex;
  grid-gap: var(--c2);
  gap: var(--c2);
  align-items: center;
  font-family: 'IBM Plex Mono' !important;
  font-size: var(--text-body-small);
  background-color: rgba(5, 82, 24, 0.363);
  border: 2px solid rgba(10, 138, 42, 0.931);
  padding: var(--c1);
  padding-left: var(--c2);
  padding-right: var(--c2);
  border-radius: var(--extraSmall);
  color: var(--light-0) !important;
}
.main_success-text__1DvKQ {
  font-family: 'IBM Plex Mono' !important;
}

/* 
*********************************
*             MISC              *
*********************************
 */

.home_game-card-sizer__1SYXw {
  padding-top: var(--c60);
  background-color: rgba(0, 0, 0, 0.4);
}
.home_game-card-container__1qEeT {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home_game-card-selectable__2X6y4 {
  cursor: pointer;
  z-index: 999;
}
.home_game-card-selectable__2X6y4 *:hover {
  background-size: 200%;
}
.home_game-card-container__1qEeT .home_inner__3ReQk {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.home_game-card-container__1qEeT .home_inner__3ReQk h1 {
  font-size: 36px;
  margin-bottom: 0;
}
.home_game-card-container__1qEeT .home_inner__3ReQk p {
  font-size: 18px;
}
.home_game-card-container__1qEeT .home_inner__3ReQk a {
  font-size: 18px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  color: var(--primary);
}
.home_news-tag__18Zid {
  color: #000;
  margin-right: var(--small);
  display: inline-block;
  background-color: #fff;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}
.home_news-card__3yEDQ {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  height: var(--c52);
  max-height: var(--c52);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.home_news-card__3yEDQ h1 {
  font-size: 24px;
  margin: 0;
}
.home_news-card__3yEDQ a {
  display: inline-block;
  color: var(--primary);
  text-transform: uppercase;
  margin-top: var(--extraLarge);
}
.home_news-card__3yEDQ:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.4);
}
.home_p-cutoff__3m8zu {
  line-height: 1.5em;
  flex: 1 1;
  overflow: hidden;
}
.home_p-cutoff__3m8zu pre {
  display: none;
}
.home_p-cutoff__3m8zu ul {
  display: none;
}
.home_p-cutoff__3m8zu img {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px #000;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--dark-3);
  margin-top: var(--small);
  margin-left: var(--large);
  margin-right: var(--large);
  object-fit: cover;
  object-position: center;
  width: calc(100% - calc(var(--large) + var(--large)));
}
.home_p-mask__3wlN7 {
  position: absolute;
  height: calc(100% - var(--c13));
  width: 100%;
  bottom: var(--c13);
  z-index: 9;
  background: linear-gradient(to top, var(--dark-1), transparent);
}
.home_date__32nli {
  margin: 0;
  margin-bottom: var(--extraSmall);
  opacity: 0.5;
}
.home_section-end__2oMAV {
  margin-top: var(--large);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_section-end__2oMAV button {
  width: 25%;
}
figcaption {
  font-family: 'IBM Plex Mono' !important;
  opacity: 0.7;
  font-size: 10pt;
  padding: 5px;
  background-color: var(--dark-0);
}

@media screen and (max-width: 700px) {
  .home_game-card-sizer__1SYXw {
    padding-top: var(--c40);
    background-color: rgba(0, 0, 0, 0.5);
  }
  .home_game-card-container__1qEeT .home_inner__3ReQk {
    display: inline-block;
    text-align: center;
    width: calc(100% - var(--extraLarge));
  }
  .home_game-card-container__1qEeT .home_inner__3ReQk p {
    font-size: 16px;
    line-height: 1.5em;
  }
  .home_game-card-container__1qEeT .home_inner__3ReQk h1 {
    font-size: 24px;
    line-height: 1.5em;
  }
  .home_section-end__2oMAV button {
    width: 100%;
  }
}

.home_auth-pip__1Oyiz {
  transition: background-color 0.1s ease;
  padding: 12px;
  border: 2px solid var(--dark-2);
  border-radius: var(--extraSmall);
  cursor: pointer;
  flex: 1 1;
}
.home_auth-pip__1Oyiz:hover {
  background-color: var(--dark-2);
}
.home_auth-pip__1Oyiz img {
  margin-bottom: -1px;
  height: 24px;
  width: 24px;
}

.home_side-img__1_46V {
  position: absolute;
  bottom: -83px;
  left: 15%;
}
@media screen and (max-width: 1416px) {
  .home_side-img__1_46V {
    left: -15%;
  }
}
@media screen and (max-width: 976px) {
  .home_side-img__1_46V {
    position: relative;
    bottom: -87px;
    left: 0;
    width: 100%;
  }
}

.footer_footer-link__UbPny {
  box-sizing: border-box;
  padding: var(--extraSmall);
  font-size: 14px;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
}

.navigation-bar_navigation-bar-container__3fOdW {
  box-sizing: border-box;
  padding: var(--c13);
  padding-top: var(--large);
  padding-bottom: var(--large);
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9) 0%,
    transparent
  );
}

.navigation-bar_navigation-inner__2ow84 {
  flex: 1 1;
  display: flex;
  max-width: calc(1416px - var(--c3));
  align-items: center;
}

.navigation-bar_navigation-bar-section__2uTZW {
  flex: 3 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-bar_navigation-bar-section__2uTZW:nth-child(2) {
  flex: 1 1;
}
.navigation-bar_logo__1ZIJa {
  height: var(--medium);
}

.navigation-bar_nav-link__QTSl9 {
  box-sizing: border-box;
  padding: var(--extraSmall);
  font-size: 14px;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
}

.navigation-bar_nav-link__QTSl9:hover {
  color: var(--primary);
}

@media screen and (max-width: 700px) {
  .navigation-bar_navigation-bar-container__3fOdW {
    padding: var(--c3);
  }
  .navigation-bar_nav-link__QTSl9 {
    display: none;
  }
}

