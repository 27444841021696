.section-container {
  width: 100%;
  background-color: var(--dark-1);
  border-top: 1px solid var(--dark-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.section-container.dark {
  background-color: var(--dark-0);
}
.section-container.light {
  background-color: var(--dark-3);
}

.section-container.grid {
  background-image: url('./grid.png');
  border-bottom: 0;
}
.section-label {
  font-size: 24px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  margin-bottom: var(--large);
}

@media screen and (max-width: 700px) {
  .section-label {
    font-size: 18px;
  }
}
