@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hero-container {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  height: calc(100vh - var(--c40));
  background-image: url('./grid.png');
  display: flex;
  align-items: center;
}

.hero-container h1 {
  font-size: 64px;
  font-weight: 600;
}
.hero-container p {
  font-size: 24px;
  font-weight: 400;
}

h1 .cursor-blink {
  display: inline-block;
  color: var(--primary);
  animation: blink 1s infinite;
}

.hero-content {
  background-image: url('./grid.png');
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .hero-container h1 {
    font-size: 42px;
  }
  .hero-content {
    padding-top: var(--c2);
  }
  .hero-container p {
    font-size: 18px;
    width: 80%;
    opacity: 0.8;
  }
  .hero-container {
    height: 85vh;
  }
  video {
    object-position: 60%;
  }
}
