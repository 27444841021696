br {
  height: 0;
  width: 0;
}

p {
  line-height: 1.5em;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
  transform: rotateZ(360);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* media query reference (maybe move all queries to one place in the future) */

@media screen and (max-width: 1919px) {
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
}

/* 
*********************************
*           PANELS             *
*********************************
 */

.arcade-ui-panel {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid var(--dark-2);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease;
  width: 100%;
}

.arcade-ui-panel.padded {
  padding: 16px;
}

.arcade-ui-panel.unpadded {
  padding: 0px;
}

.arcade-ui-panel.higher {
  background-color: var(--dark-2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.arcade-ui-panel.default {
  background-color: #1c1c1c;
}

.arcade-ui-panel.blank {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid transparent;
}

.arcade-ui-panel.centered {
  left: calc(50% - 8px);
  transform: translateX(-50%);
}

.arcade-ui-panel.hover {
  transition: width 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.arcade-ui-panel.hover:hover {
  transform: scale(1.07) translateY(-10px);
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.arcade-ui-panel.no-shadow {
  box-shadow: none;
}
.arcade-ui-panel.no-border {
  border: 1px solid transparent;
}

/* 
*********************************
*         PAGES & GRIDS         *
*********************************
 */

.arcade-ui-page-overflow {
  position: absolute;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}

.arcade-ui-page-container {
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  width: 1872px;
  min-height: 100vh;
  max-height: 100vh;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.arcade-ui-sidebar-left {
  grid-column: 1 / span 1;
}

.arcade-ui-sidebar-left-page {
  grid-column: 2 / span 3;
}

.arcade-ui-sidebar-right {
  grid-column: 4 / span 1;
}

.arcade-ui-sidebar-right-page {
  grid-column: 1 / span 3;
}

/* SPAN SIZES */

.arcade-ui-span-1 {
  grid-column: auto / span 1;
}

.arcade-ui-span-2 {
  grid-column: auto / span 2;
}

.arcade-ui-span-3 {
  grid-column: auto / span 3;
}

.arcade-ui-span-4 {
  grid-column: auto / span 4;
}

/* GRID SIZES */

.arcade-ui-grid-8 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.arcade-ui-grid-6 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.arcade-ui-grid-4 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.arcade-ui-grid-3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: var(--small);
}

.arcade-ui-grid-2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--small);
}

@media screen and (max-width: 1919px) {
  .arcade-ui-page-container {
    width: calc(100% - 32px);
  }

  .arcade-ui-page-sidebar {
    width: 25%;
  }

  .arcade-ui-page-sidebar-page {
    padding-left: 25%;
  }
}

@media screen and (max-width: 1416px) {
  /* SPAN SIZES */

  .arcade-ui-grid-3 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-3 .arcade-ui-span-3 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-3 .arcade-ui-span-4 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-4 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-3 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-4 .arcade-ui-span-4 {
    grid-column: auto / span 3;
  }

  .arcade-ui-grid-6 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-3 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-6 .arcade-ui-span-4 {
    grid-column: auto / span 3;
  }

  .arcade-ui-grid-8 .arcade-ui-span-2 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-8 .arcade-ui-span-3 {
    grid-column: auto / span 3;
  }

  .arcade-ui-grid-8 .arcade-ui-span-4 {
    grid-column: auto / span 3;
  }

  /* GRID SIZES */

  .arcade-ui-grid-3 {
    grid-template-columns: 1fr 1fr;
  }

  .arcade-ui-grid-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .arcade-ui-grid-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .arcade-ui-grid-8 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--small);
  }

  .arcade-ui-page-sidebar {
    width: 33.333%;
  }

  .arcade-ui-page-sidebar-page {
    padding-left: 33.333%;
  }
}

@media screen and (max-width: 976px) {
  /* SPAN SIZES */

  .arcade-ui-grid-3 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-3 .arcade-ui-span-3 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-3 .arcade-ui-span-4 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-3 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-4 .arcade-ui-span-4 {
    grid-column: auto / span 3;
  }

  .arcade-ui-grid-6 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-3 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-4 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-8 .arcade-ui-span-2 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-8 .arcade-ui-span-3 {
    grid-column: auto / span 3;
  }

  .arcade-ui-grid-8 .arcade-ui-span-4 {
    grid-column: auto / span 3;
  }

  /* GRID SIZES */

  .arcade-ui-grid-3 {
    grid-template-columns: 1fr;
  }

  .arcade-ui-grid-4 {
    grid-template-columns: 1fr 1fr;
  }
  .arcade-ui-grid-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .arcade-ui-grid-8 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--small);
  }

  .arcade-ui-page-sidebar {
    width: calc(100% - var(--medium));
    position: absolute;
    transform: translateX(-100vw);
  }

  .arcade-ui-page-sidebar-page {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  /* SPAN SIZES */

  .arcade-ui-grid-3 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-3 .arcade-ui-span-3 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-3 .arcade-ui-span-4 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-3 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-4 .arcade-ui-span-4 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-3 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-6 .arcade-ui-span-4 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-8 .arcade-ui-span-2 {
    grid-column: auto / span 1;
  }

  .arcade-ui-grid-8 .arcade-ui-span-3 {
    grid-column: auto / span 2;
  }

  .arcade-ui-grid-8 .arcade-ui-span-4 {
    grid-column: auto / span 2;
  }

  /* GRID SIZES */

  .arcade-ui-grid-4 {
    grid-template-columns: 1fr;
  }

  .arcade-ui-grid-6 {
    grid-template-columns: 1fr 1fr;
  }

  .arcade-ui-grid-8 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* 
*********************************
*             TEXT              *
*********************************
 */

.arcade-ui-text {
  margin-top: -0.3em;
  margin-bottom: -0.4em;
  display: inline-block;
  font-size: var(--text-body-medium);
}

.arcade-ui-text-mono {
  text-transform: uppercase;
  font-family: 'IBM Plex Mono' !important;
  display: inline-block;
  letter-spacing: 0.05em;
}

.arcade-ui-text.body {
  line-height: 1.9em;
}

.arcade-ui-text.header.large,
.arcade-ui-text-mono.header.large {
  font-weight: bold;
  font-size: var(--text-header-large);
}

.arcade-ui-text.header.small,
.arcade-ui-text-mono.header.small {
  font-weight: bold;
  font-size: var(--text-header-small);
}

.arcade-ui-text.header.medium,
.arcade-ui-text-mono.header.medium {
  font-weight: bold;
  font-size: var(--text-header-medium);
}

.arcade-ui-text.body.large,
.arcade-ui-text-mono.body.large {
  font-size: var(--text-body-large);
}

.arcade-ui-text.body.small,
.arcade-ui-text-mono.body.small {
  font-size: var(--text-body-small);
}

.arcade-ui-text.body.medium,
.arcade-ui-text-mono.body.medium {
  font-size: var(--text-body-medium);
}

.arcade-ui-text.display,
.arcade-ui-text-mono.display {
  font-weight: bold;
  font-size: var(--text-display);
}

.arcade-ui-text-overflow {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

/* 
*********************************
*           BUTTONS             *
*********************************
 */

.arcade-ui-button {
  display: flex;
  gap: var(--small);
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;
  color: #fff;
  text-transform: capitalize;
}

.arcade-ui-button .arcade-ui-text {
  margin-top: -0.5em;
}

.arcade-ui-button.medium {
  border: 0;
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
}

.arcade-ui-button.large {
  border: 0;
  height: var(--c7);
  padding-left: 40px;
  padding-right: 40px;
}

.arcade-ui-button.small {
  font-weight: normal;
  border: 0;
  padding: 4.5px;
  padding-left: 16px;
  padding-right: 16px;
}

.arcade-ui-button.primary,
.arcade-ui-button.submit,
.arcade-ui-button.floating-action,
.arcade-ui-button.floating-action-desktop {
  font-weight: bold;
  background-color: var(--primary);
  color: var(--dark-0) !important;
}

.arcade-ui-button.secondary {
  background-color: var(--dark-2);
}

.arcade-ui-button.round {
  border-radius: 8px;
}

.arcade-ui-button.rounder {
  border-radius: 16px;
}

.arcade-ui-button.roundest {
  border-radius: 999px;
}

.arcade-ui-button:hover {
  filter: brightness(1.2);
}

.arcade-ui-button:active {
  filter: brightness(0.9);
}

.arcade-ui-button.floating-action {
  display: none;
  z-index: 999;
}

.arcade-ui-button.blank {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 16px;
  color: var(--dark-5);
}

.arcade-ui-button.icon {
  background-color: var(--dark-2);
  max-height: 42px;
  max-width: 42px;
  padding: 13px;
  border-radius: 999px;
  color: var(--dark-5);
}

.arcade-ui-button.blank:hover {
  background-color: rgba(255, 255, 255, 0.165);
}

.arcade-ui-button.link {
  background-color: transparent;
  font-size: var(--text-body-small) !important;
  color: var(--primary);
}
.arcade-ui-button.link .arcade-ui-text {
  font-size: var(--text-body-small) !important;
}

@media screen and (max-width: 976px) {
  .arcade-ui-button.floating-action-desktop {
    display: none;
  }

  .arcade-ui-button.floating-action {
    min-width: 25vw;
    display: flex;
    position: fixed;
    bottom: 24px;
    right: 24px;
    border-radius: 999px;
  }
}

@media screen and (max-width: 700px) {
  .arcade-ui-button.floating-action-desktop {
    display: none;
  }

  .arcade-ui-button.floating-action {
    display: flex;
    position: fixed;
    width: calc(100vw - 28px);
    bottom: 14px;
    right: 14px;
    border-radius: 999px;
  }
}

/* 
*********************************
*          TABLE-ROW            *
*********************************
 */
.vertical-table {
  position: relative;
  float: left;
  margin-left: var(--large);
  width: calc(100% - var(--large));
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-row:last-child {
  border-bottom: 0;
}

.table-row {
  float: left;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: var(--medium);
  padding-bottom: var(--medium);
  padding-right: var(--medium);
  border-bottom: 1px solid var(--dark-1);
}

.table-row div {
  flex: 1;
}

/* 
*********************************
*            MODALS             *
*********************************
 */

@keyframes modal {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes modal-mobile {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.modal-transition-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 999;
  overflow: hidden;
}

.modal-bg {
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow: hidden;
  overflow: hidden;
}

.modal-panel {
  animation: modal 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #414141;
  background-color: var(--dark-1);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  width: calc(25% - 4px);
  text-align: center;
}

.modal-panel.alt {
  border: 0;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
  padding: var(--large);
}

.modal-action-strip {
  box-sizing: border-box;
  border-top: 1px solid var(--dark-2);
  display: flex;
  flex-direction: row;
  padding: var(--small);
  margin-top: var(--small);
  width: 100%;
  gap: var(--small);
}

.modal-action-strip button {
  flex: 1;
}
.modal-action-strip.vertical {
  flex-direction: column;
}
.modal-action-strip.vertical button {
  flex: none;
}

.modal-panel-content {
  display: flex;
  flex-direction: column;
  padding: var(--medium);
  overflow-y: auto;
}

@media screen and (max-width: 1416px) {
  .modal-panel {
    width: 33%;
  }
}

@media screen and (max-width: 976px) {
  .modal-panel {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .modal-panel {
    position: relative;
    width: 100%;
    height: calc(100% - var(--c10));
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: calc(-1 * var(--extraSmall));
    animation: modal-mobile 0.7s cubic-bezier(0.5, 0.5, 0, 1);
  }
  .modal-panel.alt {
    height: auto;
    position: unset;
    animation: modal 0.3s ease-out;
  }
  .modal-panel-content {
    flex: 1;
  }
  .modal-action-strip {
    flex-direction: column;
  }
  .modal-action-strip button {
    min-height: var(--c7);
  }
  .modal-bg {
    align-items: flex-end;
  }
}

/* 
*********************************
*          TEXT-INPUT           *
*********************************
 */

input[type='text'].text-input,
input[type='password'].text-input {
  color: #fff;
  background-color: var(--dark-0);
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 0px var(--dark-1);
  border-radius: var(--extraSmall);
  padding: var(--small);
  font-size: var(--text-body-small);
}
textarea.text-input-area {
  color: #fff;
  background-color: var(--dark-0);
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 0px var(--dark-1);
  border-radius: var(--extraSmall);
  padding: var(--small);
  font-size: var(--text-body-small);
  resize: vertical;
  max-height: 30vh;
  min-height: var(--c4);
}

/* 
*********************************
*            PILLS              *
*********************************
 */

.pill {
  box-sizing: border-box;
  background-color: transparent;
  color: #fff;
  padding: var(--extraSmall);
  padding-left: var(--small);
  padding-right: var(--small);
  border: 2px solid transparent;
  border-radius: 999px;
  cursor: pointer;
  transition: filter 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
  position: relative;
  text-transform: uppercase;
  font-family: 'IBM Plex Mono';
}
.pill.notification::after {
  color: rgb(231, 132, 40);
  font-size: 32px;
  content: '•';
  position: absolute;
  top: -20px;
  right: -5px;
}
.pill:hover {
  border: 2px solid var(--dark-2);
  filter: brightness(1.2);
}
.pill:active {
  transform: scale(0.9);
  background-color: var(--dark-0);
  border-color: var(--dark-0);
}
.pill.active {
  background-color: var(--dark-2);
  color: var(--dark-5);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

/* 
*********************************
*             NAV               *
*********************************
 */

.nav-spacer {
  height: 93px;
}

/* 
*********************************
*           CHECKBOX            *
*********************************
 */
.checkbox {
  appearance: none;
  height: var(--c3);
  width: var(--c3);
  box-sizing: border-box;
  border: 2px solid var(--dark-4);
  border-radius: 4px;
  box-shadow: 0px 1px 2px var(--dark-0);
  position: relative;
}
.checkbox:checked {
  appearance: none;
  background-color: var(--primary);
  border-color: var(--primary);
}
.checkbox:checked::after {
  top: 60%;
  left: 68%;
  transform: translate(-50%, -50%) rotate(130deg);
  color: var(--dark-1);
  font-family: 'IBM Plex Sans' !important;
  font-size: var(--text-header-large);
  font-weight: bold;
  content: '¬';
  position: absolute;
}

/* 
*********************************
*           ERROR             *
*********************************
 */

.error-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.error {
  animation: modal 0.5s ease;
  display: flex;
  gap: var(--c2);
  align-items: center;
  font-family: 'IBM Plex Mono' !important;
  font-size: var(--text-body-small);
  background-color: rgba(255, 10, 10, 0.1);
  border: 2px solid rgba(255, 10, 10, 0.15);
  padding: var(--c1);
  padding-left: var(--c2);
  padding-right: var(--c2);
  border-radius: var(--extraSmall);
  color: var(--red);
}
.error-text {
  font-family: 'IBM Plex Mono' !important;
}

/* 
*********************************
*           SUCCESS             *
*********************************
 */

.success-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.success {
  animation: modal 0.5s ease;
  display: flex;
  gap: var(--c2);
  align-items: center;
  font-family: 'IBM Plex Mono' !important;
  font-size: var(--text-body-small);
  background-color: rgba(5, 82, 24, 0.363);
  border: 2px solid rgba(10, 138, 42, 0.931);
  padding: var(--c1);
  padding-left: var(--c2);
  padding-right: var(--c2);
  border-radius: var(--extraSmall);
  color: var(--light-0) !important;
}
.success-text {
  font-family: 'IBM Plex Mono' !important;
}

/* 
*********************************
*             MISC              *
*********************************
 */
