.game-card-sizer {
  padding-top: var(--c60);
  background-color: rgba(0, 0, 0, 0.4);
}
.game-card-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.game-card-selectable {
  cursor: pointer;
  z-index: 999;
}
.game-card-selectable *:hover {
  background-size: 200%;
}
.game-card-container .inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.game-card-container .inner h1 {
  font-size: 36px;
  margin-bottom: 0;
}
.game-card-container .inner p {
  font-size: 18px;
}
.game-card-container .inner a {
  font-size: 18px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  color: var(--primary);
}
.news-tag {
  color: #000;
  margin-right: var(--small);
  display: inline-block;
  background-color: #fff;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}
.news-card {
  transition: transform 0.3s ease;
  height: var(--c52);
  max-height: var(--c52);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.news-card h1 {
  font-size: 24px;
  margin: 0;
}
.news-card a {
  display: inline-block;
  color: var(--primary);
  text-transform: uppercase;
  margin-top: var(--extraLarge);
}
.news-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.4);
}
.p-cutoff {
  line-height: 1.5em;
  flex: 1;
  overflow: hidden;
}
.p-cutoff pre {
  display: none;
}
.p-cutoff ul {
  display: none;
}
.p-cutoff img {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px #000;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--dark-3);
  margin-top: var(--small);
  margin-left: var(--large);
  margin-right: var(--large);
  object-fit: cover;
  object-position: center;
  width: calc(100% - calc(var(--large) + var(--large)));
}
.p-mask {
  position: absolute;
  height: calc(100% - var(--c13));
  width: 100%;
  bottom: var(--c13);
  z-index: 9;
  background: linear-gradient(to top, var(--dark-1), transparent);
}
.date {
  margin: 0;
  margin-bottom: var(--extraSmall);
  opacity: 0.5;
}
.section-end {
  margin-top: var(--large);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-end button {
  width: 25%;
}
figcaption {
  font-family: 'IBM Plex Mono' !important;
  opacity: 0.7;
  font-size: 10pt;
  padding: 5px;
  background-color: var(--dark-0);
}

@media screen and (max-width: 700px) {
  .game-card-sizer {
    padding-top: var(--c40);
    background-color: rgba(0, 0, 0, 0.5);
  }
  .game-card-container .inner {
    display: inline-block;
    text-align: center;
    width: calc(100% - var(--extraLarge));
  }
  .game-card-container .inner p {
    font-size: 16px;
    line-height: 1.5em;
  }
  .game-card-container .inner h1 {
    font-size: 24px;
    line-height: 1.5em;
  }
  .section-end button {
    width: 100%;
  }
}

.auth-pip {
  transition: background-color 0.1s ease;
  padding: 12px;
  border: 2px solid var(--dark-2);
  border-radius: var(--extraSmall);
  cursor: pointer;
  flex: 1;
}
.auth-pip:hover {
  background-color: var(--dark-2);
}
.auth-pip img {
  margin-bottom: -1px;
  height: 24px;
  width: 24px;
}

.side-img {
  position: absolute;
  bottom: -83px;
  left: 15%;
}
@media screen and (max-width: 1416px) {
  .side-img {
    left: -15%;
  }
}
@media screen and (max-width: 976px) {
  .side-img {
    position: relative;
    bottom: -87px;
    left: 0;
    width: 100%;
  }
}
