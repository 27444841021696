.navigation-bar-container {
  box-sizing: border-box;
  padding: var(--c13);
  padding-top: var(--large);
  padding-bottom: var(--large);
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9) 0%,
    transparent
  );
}

.navigation-inner {
  flex: 1;
  display: flex;
  max-width: calc(1416px - var(--c3));
  align-items: center;
}

.navigation-bar-section {
  flex: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-bar-section:nth-child(2) {
  flex: 1;
}
.logo {
  height: var(--medium);
}

.nav-link {
  box-sizing: border-box;
  padding: var(--extraSmall);
  font-size: 14px;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
}

.nav-link:hover {
  color: var(--primary);
}

@media screen and (max-width: 700px) {
  .navigation-bar-container {
    padding: var(--c3);
  }
  .nav-link {
    display: none;
  }
}
