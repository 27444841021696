html,
body {
  background-color: #000;
  padding: 0;
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

* {
  font-family: 'IBM Plex Sans', 'IBM Plex Mono', sans-serif;
}

:root {
  --primary: #e2e600;
  --red: #ff3b20;
  --dark-0: #000000;
  --dark-1: #181819;
  --dark-2: #2c2c2d;
  --dark-3: #404142;
  --dark-4: #606163;
  --dark-5: #fff;

  --c1: 8px;
  --c2: 16px;
  --c3: 24px;
  --c4: 32px;
  --c5: 40px;
  --c6: 48px;
  --c7: 56px;
  --c8: 64px;
  --c9: 72px;
  --c10: 80px;
  --c11: 88px;
  --c12: 96px;
  --c13: 104px;
  --c14: 112px;
  --c15: 120px;
  --c16: 128px;
  --c17: 136px;
  --c18: 144px;
  --c19: 152px;
  --c20: 160px;
  --c21: 168px;
  --c22: 176px;
  --c23: 184px;
  --c24: 192px;
  --c25: 200px;
  --c26: 208px;
  --c27: 216px;
  --c28: 224px;
  --c29: 232px;
  --c30: 240px;
  --c31: 248px;
  --c32: 256px;
  --c33: 264px;
  --c34: 272px;
  --c35: 280px;
  --c36: 288px;
  --c37: 296px;
  --c38: 304px;
  --c39: 312px;
  --c40: 320px;
  --c41: 328px;
  --c42: 336px;
  --c43: 344px;
  --c44: 352px;
  --c45: 360px;
  --c46: 368px;
  --c47: 376px;
  --c48: 384px;
  --c49: 392px;
  --c50: 400px;
  --c51: 408px;
  --c52: 416px;
  --c53: 424px;
  --c54: 432px;
  --c55: 440px;
  --c56: 448px;
  --c57: 456px;
  --c58: 464px;
  --c59: 472px;
  --c60: 480px;
  --c61: 488px;
  --c62: 496px;
  --c63: 504px;
  --c64: 512px;
  --c65: 520px;
  --c66: 528px;
  --c67: 536px;
  --c68: 544px;
  --c69: 552px;
  --c70: 560px;
  --c71: 568px;
  --c72: 576px;
  --c73: 584px;
  --c74: 592px;
  --c75: 600px;
  --c76: 608px;
  --c77: 616px;
  --c78: 624px;
  --c79: 632px;
  --c80: 640px;
  --c81: 648px;
  --c82: 656px;
  --c83: 664px;
  --c84: 672px;
  --c85: 680px;
  --c86: 688px;
  --c87: 696px;
  --c88: 704px;
  --c89: 712px;
  --c90: 720px;
  --c91: 728px;
  --c92: 736px;
  --c93: 744px;
  --c94: 752px;
  --c95: 760px;
  --c96: 768px;
  --c97: 776px;
  --c98: 784px;
  --c99: 792px;
  --c100: 800px;

  --extraSmall: 8px;
  --small: 16px;
  --medium: 24px;
  --large: 40px;
  --extraLarge: 64px;

  --text-body-small: 12pt;
  --text-body-medium: 14pt;
  --text-body-large: 16pt;

  --text-header-small: 18px;
  --text-header-medium: 22px;
  --text-header-large: 33px;

  --text-display: 54px;
}

.App {
  color: #fff;
}
br {
  height: 0;
  width: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.link {
  color: var(--primary);
}
button {
  color: #fff;
  transition: 0.1s ease;
  box-sizing: border-box;
  border: 1px solid var(--dark-2);
  background-color: var(--dark-0);
  border-radius: var(--extraSmall);
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  font-size: 14px;
  padding: var(--medium);
  cursor: pointer;
}
button:hover {
  background-color: var(--dark-1);
}

.button-like {
  font-size: 18px;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  color: var(--primary);
}

.latitude-web-wrapper {
  color: #fff;
  width: calc(1416px - var(--c3));
  padding-top: var(--c9);
  padding-bottom: var(--c9);
}
@media screen and (max-width: 1626px) {
  .latitude-web-wrapper {
    width: calc(100% - var(--c26));
    padding-top: var(--c9);
    padding-bottom: var(--c9);
  }
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
  .latitude-web-wrapper {
    width: calc(100% - var(--c6));
  }
}

/* media query reference (maybe move all queries to one place in the future) */

@media screen and (max-width: 2140px) {
}

@media screen and (max-width: 1416px) {
}

@media screen and (max-width: 976px) {
}

@media screen and (max-width: 700px) {
}
/* 
*********************************
*             FORMS             *
*********************************
 */

input[type='text'],
input[type='email'],
select,
textarea {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--dark-2);
  box-shadow: inset 0px 0px 4px var(--dark-0);
  border-radius: var(--extraSmall);
  padding: var(--small);
  font-size: var(--text-body-small);
  resize: vertical;
}

label {
  margin: var(--small);
  margin-bottom: var(--extraSmall);
}

input.btn {
  color: var(--dark-1);
  background-color: var(--primary);
  border: 1px solid var(--dark-2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: var(--extraSmall);
  padding: var(--small);
  padding-left: var(--medium);
  padding-right: var(--medium);
  font-size: var(--text-body-small);
  display: inline-block;
  text-transform: uppercase;
  font-family: 'IBM Plex Mono';
  cursor: pointer;
  flex: none;
  width: fit-content;
}

/* 
*********************************
*           ARTICLES            *
*********************************
 */

.article {
  box-sizing: border-box;
  width: 50vw;
  line-height: 1.8em;
  margin: 0 auto;
  margin-top: 5vw;
  margin-bottom: 5vw;
  border-radius: var(--extraSmall);
  background-color: var(--dark-1);
  border: 1px solid var(--dark-2);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  padding: var(--extraLarge);
}
.article h1 {
  font-size: 64px;
  line-height: 1em;
}
.article a {
  text-decoration: underline;
  color: var(--primary);
}
.article p {
  letter-spacing: 0.03em;
  margin-top: var(--medium);
  margin-bottom: var(--medium);
}
.article img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--dark-3);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.article figure {
  text-align: center;
}
.article code {
  box-sizing: border-box;
  background-color: var(--dark-0);
  padding: var(--small);
  overflow-x: scroll;
  display: inline-block;
  max-width: 100%;
  font-family: 'IBM Plex Mono';
  border: 1px solid var(--dark-3);
  border-radius: 4px;
  font-size: 14px;
}
.article figure img {
  width: 75%;
  border-radius: 4px;
  border: 1px solid var(--dark-3);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
.article figcaption {
  width: 75%;
  margin: 0 auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}
p.article-date {
  font-family: 'IBM Plex Mono';
  margin: 0;
  opacity: 0.5;
  letter-spacing: 0em;
}

li {
  margin-bottom: var(--medium);
  list-style-type: circle;
}

h3 {
  margin-top: 0;
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
}

form {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .article h1 {
    font-size: 40px;
  }
  .article {
    width: 100%;
    padding: var(--small);
    margin-top: 10vw;
  }
}

.vertical-table {
  position: relative;
  float: left;
  width: calc(100% - var(--large));
}

.table-row:last-child {
  border-bottom: 0;
}

.table-row {
  cursor: pointer;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: var(--medium);
  padding-bottom: var(--medium);
  padding-right: var(--medium);
  border-bottom: 1px solid var(--dark-2);
  width: 100%;
}

.table-anchor {
  flex: 1;
  display: flex;
  margin-left: var(--large);
  width: calc(100% - var(--large));
  border-bottom: 1px solid var(--dark-2);
}
.table-anchor:last-child {
  border-bottom: 0;
}
